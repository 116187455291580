import logo from './logo.svg';
import './App.css';
// import { Routes, Route, Link, NavLink } from "react-router-dom";
import Home from './components/Home/Home';
import Service from './components/Home/Service';
import Technologies from './components/Home/Technologies';
import Portfolio from './components/Home/Portfolio';
import About from './components/Home/About';
import Contact from './components/Home/Contact';
import Portfolio1 from './components/Home/Portfolio1';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>

      <BrowserRouter>
        <Routes>
          
            {/* <Route path="/"  element={<Home />} /> */}
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="service" element={<Service />} />
            <Route path="technologies" element={<Technologies />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="portfolio1" element={<Portfolio1 />} />

        </Routes>
      </BrowserRouter>
      
    </>
  );
}

export default App;
