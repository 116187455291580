import React, { useState } from "react";
import './Service.css';
import './Home.css';
import { Routes, Route, Link, NavLink } from "react-router-dom";
import { AiOutlineEnvironment, } from "react-icons/ai";
import { FaPhoneSquare, } from "react-icons/fa";
import { IoMdMail, } from "react-icons/io";
import { GiFiles, } from "react-icons/gi";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsWhatsapp, } from "react-icons/bs";

const Home = () => {

    const [mobside, setMobside] = useState(0);

    const openNav = () => {
        setMobside(250);
    }

    const closeNav = () => {
        setMobside(0);
    }


    return (
        <>

            <section className='homeHead homemainingbg'>

                <div className='homesubh1'>
                    <div className='subhead11'>
                        <a href="../">
                            <img src={require('../../asset/img/keplogo.png')} alt="logo" className='homeheadlogo headimgserv' style={{ width: 170 }} />
                        </a>
                    </div>
                    <div className='subhead12'>
                        <nav className='navtab' >
                            <ul className="ulhead">
                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/home">Home</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/service">Services</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/technologies">Technologies</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/portfolio1">Portfolio</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/about">About</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/contact">Contact</NavLink></li>
                            </ul>
                        </nav>

                        <div className='mobsidemew'>
                            <span className='mobsidemew1' onClick={() => openNav()} >&#9776;</span>
                        </div>

                    </div>
                </div>
                <div className='subhead2'>

                    <div className='homehello'>
                        <p className='helo'>Hello! Welcom to</p>
                        <p className='title1'>KEPREVOS ENTERPRISE BUSINESS</p>
                        <p className='title2'>SOLUTIONS PVT LTD</p>
                        <p className='sucss'>Success is liking yourself, liking what you do, and liking how you do it.</p>
                    </div>

                </div>

            </section>

            <section id="mySidenav" style={{ width: mobside }} class="sidenav">
                <a class="closebtn" onClick={() => closeNav()}>&times;</a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/home">Home</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/service">Services</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/technologies">Technologies</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/portfolio1">Portfolio</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/about">About</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/contact">Contact</NavLink>
                </a>
            </section>

            <section className='mainhom1'>

                <div className='maincardhome1'>

                    <div className='maincard1'>

                        <div className='subcard1 scardhome'>

                            <div className='scard1 webpara'>
                                <h3 className='para1scardhome'>OUR MISSION</h3>
                                <p className='para2scardhome'>Our mission is to offer the clients with cost-effective services without sacrificing...</p>
                            </div>
                            <div className='scard1home'>

                                <div className='cardbtnhome'>
                                    <p className='readText'>Details</p>
                                </div>

                            </div>

                        </div>
                        <div className='subcard1 scardhome'>

                            <div className='scard1 webpara'>
                                <h3 className='para1scardhome'>OUR VISION</h3>
                                <p className='para2scardhome'>To become a prime performer in developing high-quality web apps and software...</p>
                            </div>
                            <div className='scard1home'>

                                <div className='cardbtnhome'>
                                    <p className='readText'>Details</p>
                                </div>

                            </div>

                        </div>
                        <div className='subcard1 scardhome'>

                            <div className='scard1 webpara'>
                                <h3 className='para1scardhome'>CORE VALUES</h3>
                                <p className='para2scardhome'>- Trust <br />
                                    - Integrity & Ethnic Values<br />
                                    - Listen, care, serve and do the...</p>
                            </div>
                            <div className='scard1home'>

                                <div className='cardbtnhome'>
                                    <p className='readText'>Details</p>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <section className='mainhom2'>
                <div className='mainhom2sub'>

                    <h3 className='keptxt1'>KEPREVOS</h3>

                    <p className='keptxt2'>The success of KEPREVOS is due to the experience, hard work, and initiative of our experienced professionals.</p>

                    <p className='keptxt3'>KEPREVOS is one of the top-notch and superior Web application and software development companies in Pondicherry, India that provides high-quality services We have experienced and professional app developers who are experts in developing the best quality applications as per the requirements and expectations of our clients.</p>

                    <p className='keptxt4'>Our specialized experts analyze the needs of the clients, come up with genuine ideas and choose the one that will best suit their needs, and develop a compelling app.</p>

                </div>
            </section>

            <section className='homprojectsec'>
                <div className='homprosec1'>
                    <span className='linesp'>
                        <div class="vl"></div>
                    </span>
                    <h2 className='serhomeline'>Projects</h2>
                    <p className='serhomeline1'>Our portfolio speaks better than words!</p>
                </div>
            </section>

            <div style={{ padding: 15 }}></div>

            <section className='homcorsec'>

                <div id="myCarousel" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                    </ol>

                    <div class="carousel-inner">
                        <div class="item active">
                            <div className='row' >
                                <div className='col mx-1 newcor hide1'>
                                    <img src={require('../../asset/img/carousel/carouselimg1.jpg')} alt="Los Angeles" className='curimgc' />
                                </div>
                                <div className='col mx-1 newcor hide1'>
                                    <img src={require('../../asset/img/carousel/carouselimg2.jpg')} alt="Los Angeles" className='curimgc' />
                                </div>
                                <div className='col mx-1 newcor'>
                                    <img src={require('../../asset/img/carousel/carouselimg3.jpg')} alt="Los Angeles" className='curimgc' />
                                </div>
                                <div className='col mx-1 newcor'>
                                    <img src={require('../../asset/img/carousel/carouselimg4.jpg')} alt="Los Angeles" className='curimgc' />
                                </div>
                                <div className='col mx-1 newcor'>
                                    <img src={require('../../asset/img/carousel/carouselimg5.jpg')} alt="Los Angeles" className='curimgc' />

                                </div>
                            </div>

                        </div>

                        <div class="item">
                            <div className='row' >
                                <div className='col mx-1 newcor'>
                                    <img src={require('../../asset/img/carousel/carouselimg6.jpg')} alt="Los Angeles" className='curimgc' />
                                </div>
                                <div className='col mx-1 newcor'>
                                    <img src={require('../../asset/img/carousel/carouselimg7.jpg')} alt="Los Angeles" className='curimgc' />
                                </div>
                                <div className='col mx-1 newcor'>
                                    <img src={require('../../asset/img/carousel/carouselimg1.jpg')} alt="Los Angeles" className='curimgc' />
                                </div>
                                <div className='col mx-1 newcor hide1'>
                                    <img src={require('../../asset/img/carousel/carouselimg2.jpg')} alt="Los Angeles" className='curimgc' />
                                </div>
                                <div className='col mx-1 newcor hide1'>
                                    <img src={require('../../asset/img/carousel/carouselimg3.jpg')} alt="Los Angeles" className='curimgc' />

                                </div>
                            </div>
                        </div>



                    </div>
                </div>

            </section>

            <section className='viewsec'>
                <div className='btnonline'>
                    <p>View full portfolio</p>
                    <GiFiles className='mailbrn2' />
                </div>
            </section>

            <section className='homservicesec'>
                <div className='homservicesec1'>
                    <span className='linesp'>
                        <div class="vl"></div>
                    </span>
                    <h2 className='serhomeline'>Services</h2>
                    <p className='serhomeline1'>We love & know what we do!</p>
                </div>
            </section>

            <section className='homecardsec'>
                <div className='row homecardsec1'>
                    <div className='col-lg-4 col-sm-12 newhomeborder' >
                        <div className='homecardsec2'>
                            <img src={require('../../asset/img/cardimg1.png')} className='homecardimg' alt="logo" style={{ width: 50 }} />
                            <h4 className='homewebsir'>Website – Responsive, PWA</h4>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 newhomeborder' >
                        <div className='homecardsec2'>
                            <img src={require('../../asset/img/cardimg2.png')} className='homecardimg' alt="logo" style={{ width: 50 }} />
                            <h4 className='homewebsir'>Web Applications</h4>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 newhomeborder' >
                        <div className='homecardsec2'>
                            <img src={require('../../asset/img/cardimg3.png')} className='homecardimg' alt="logo" style={{ width: 50 }} />
                            <h4 className='homewebsir'>Mobile App Development</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className='homecard2sec'>
                <div className='row homecardsec1'>
                    <div className='col-lg-4 col-sm-12 newhomeborder' >
                        <div className='homecardsec2'>
                            <img src={require('../../asset/img/cardimg4.png')} className='homecardimg' alt="logo" style={{ width: 50 }} />
                            <h4 className='homewebsir'>eCommerce</h4>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 newhomeborder' >
                        <div className='homecardsec2'>
                            <img src={require('../../asset/img/cardimg5.png')} className='homecardimg' alt="logo" style={{ width: 50 }} />
                            <h4 className='homewebsir'>Analytics</h4>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 newhomeborder' >
                        <div className='homecardsec2'>
                            <img src={require('../../asset/img/cardimg6.png')} className='homecardimg' alt="logo" style={{ width: 50 }} />
                            <h4 className='homewebsir'>Customer Support Services</h4>
                        </div>
                    </div>
                </div>
            </section>

            <div className="homedum11"></div>

            <section className='free freehonenew'>

                <div className='freeSub'>

                    <div className='freeSub1 '>
                        <h2 className='freefont'>Free consultation!</h2>
                    </div>
                    <div className='freeSub1 inocard homefreenew'>
                        <h2 className='freefont1'>+91 6379444534</h2>
                        <p className='info'>info@keprevos.com</p>
                    </div>
                    <div className='freeSub1 mailcard'>

                        <div className='btnonline'>
                            <p>Online contact form</p>
                            <IoMdMail className='mailbrn2' />

                        </div>

                    </div>

                </div>

            </section>

            <section className='homhowsec'>
                <div className='homhowsec1'>
                    <span className='linesp'>
                        <div class="vl"></div>
                    </span>
                    <h2 className='serhomeline'>How we work</h2>
                    <p className='serhomeline1'>Don’t be busy – be productive!</p>
                </div>
            </section>

            <section className='homecardseccount01'>
                <div className='row homecardseccount1'>
                    <div className='col-lg-8 col-sm-12 bornewrec imgnhomenew'>
                        <div className='carbgimg'>

                        </div>
                    </div>

                    <div className='col-lg-4 col-sm-12 bornewrec'>
                        <div className='homecardseccount2'>
                            <h2 className='intetext'>01</h2>
                            <h4 className='planing'>Gathering data</h4>
                            <p className='para02'>The key to effective data collection is getting the right information in the first place, then processing and interpreting it. Keprevos can be used to improve business performance from business intelligence and analytics to decision support and data management.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='homecardseccount'>
                <div className='row homecardseccount1'>
                    <div className='col-lg-4 col-sm-12 bornewrec' >
                        <div className='homecardseccount2'>
                            <h2 className='intetext'>02</h2>
                            <h4 className='planing'>Planning</h4>
                            <p className='para02'>For continuous development, Keprevos Planning provides comprehensive overviews, lifecycles, and intelligence. A full range of technology, platform, and market sets can be assessed, articulated and managed with Keprevos. Ensure clarity throughout the lifecycle of your project with Keprevos.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 bornewrec' >
                        <div className='homecardseccount2'>
                            <h2 className='intetext'>03</h2>
                            <h4 className='planing'>Implementation</h4>
                            <p className='para02'>When software is adopted, it must be implemented in a way that meets the requirements and functionality of the organization. The implementation of this can be completed through a number of initiatives, so determining what these initiatives and solutions will be before implementation is essential.</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 bornewrec' >
                        <div className='homecardseccount04'>
                            <h2 className='intetext04'>04</h2>
                            <h4 className='planing04'>Results</h4>
                            <p className='para04'>There is a strong focus on results, and we are always striving to achieve the best results. This production process was excellently composed, and the output was excellently delivered.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='homefreefoot'>

                <div className='homefreeSubfoot'>

                    <div className='freeSub1 '>
                        {/* <h2 className='freefont'>Free consultation!</h2> */}
                        <img src={require('../../asset/img/keplogo.png')} alt="logo" className='homeheadlogo' style={{ width: '20em' }} />

                    </div>
                    <div className='freeSub1 inocard'>
                        <h2 className='freefont'>+91 6379444534</h2>
                        <p className='homeinfo'>info@keprevos.com</p>
                    </div>
                    <div className='freeSub1 mailcard'>

                        <div className='homebtnonline'>
                            <p>contact form</p>
                            <IoMdMail className='mailbrn2' />

                        </div>

                    </div>

                </div>

            </section>

            <section className='serFooter'>

                <div class='row footrow' style={{ margin: 0 }}>
                    <div class="col-12 col-md-12 col-lg-6 foot1">
                        <span className='footTxt1'>© 2020 KEPREVOS - All Rights Reserved</span>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 foot2">
                        <span className='footTxt2'>
                            <FaPhoneSquare className='footIcon' />
                            <p>+91 6379444534 </p>
                        </span>

                        <span className='footTxt2'>
                            <IoMdMail className='footIconmail' />
                            <p>management@keprevos.com </p>
                        </span>

                        <span className='footTxt2'>
                            <AiFillFacebook className='footIconmail' />
                        </span>

                        <span className='footTxt2'>
                            <BsWhatsapp className='footIconwatt' />
                        </span>

                        <span className='footTxt2'>
                            <AiFillLinkedin className='footIconwatt' />
                        </span>

                    </div>
                </div>

            </section>

        </>
    )
}

export default Home