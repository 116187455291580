import React, { useState } from "react";
import './About.css';
import './Service.css';
import './Home.css';
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { AiOutlineEnvironment, } from "react-icons/ai";
import { FaPhoneSquare, } from "react-icons/fa";
import { IoMdMail, } from "react-icons/io";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsWhatsapp, } from "react-icons/bs";

const About = () => {

    const [mobside, setMobside] = useState(0);

    const openNav = () => {
        setMobside(250);
    }

    const closeNav = () => {
        setMobside(0);
    }

    return (
        <>
            <section className='serHead'>

                <div className='subhead1'>
                    <div className='subhead11'>
                        <a href="../">
                            <img src={require('../../asset/img/keplogo.png')} alt="logo" style={{ width: 170 }} className="headimgserv" />
                        </a>
                    </div>
                    <div className='subhead12'>
                        <nav className='navtab' >
                            <ul className="ulhead">
                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/home">Home</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/service">Services</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/technologies">Technologies</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/portfolio1">Portfolio</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/about">About</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/contact">Contact</NavLink></li>
                            </ul>
                        </nav>
                        <div className='mobsidemew'>
                            <span className='mobsidemew1' onClick={() => openNav()} >&#9776;</span>
                        </div>

                    </div>
                </div>
                <div className='subhead2'>

                    <span className='subhead_text1'>ABOUT COMPANY</span>
                    <span className='subhead_text2'>Who we are & what we do</span>
                    {/* <span className='subhead_text3'>Home / Services</span> */}

                </div>

            </section>

            <section id="mySidenav" style={{ width: mobside }} class="sidenav">
                <a class="closebtn" onClick={() => closeNav()}>&times;</a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/home">Home</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/service">Services</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/technologies">Technologies</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/portfolio1">Portfolio</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/about">About</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/contact">Contact</NavLink>
                </a>
            </section>

            <section class="about-us-content">
                <div class="container aboutnew mt-5 p-4">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 mb-2">
                            <h2 className="topic-head">KEPREVOS</h2>
                            <p className="para-text">KEPREVOS is one of the top-notch and superior Web application and software development companies in Pondicherry, India that provides high-quality services</p>
                            <p className="para-text">We have experienced and professional app developers who are experts in developing the best quality applications as per the requirements and expectations of our clients. Our specialized experts analyze the needs of the clients, come up with genuine ideas and choose the one that will best suit their needs, and develop a compelling app.</p>
                        </div>
                        <div class="col-xl-12 col-md-12 mb-2">
                            <h2 className="topic-head">OUR SUCCESS</h2>
                            <p className="para-text">Our commitment, capability, and credibility to create, host and optimize web applications remotely are proved by our top-notch mobile apps and web application software which allow customers to access the data from any time and anywhere from any device. The success of KEPREVOS is due to the experience, hard work, and initiative of our experienced professionals. KEPREVOS takes up projects and product development services internationally from different countries namely France, Canada, and Singapore.</p>
                        </div>
                        <div class="col-xl-12 col-md-12 mb-2">
                            <h2 className="topic-head">OUR COMPETENCE</h2>
                            <p className="para-text">We focus on different areas in Software development namely</p>
                            <ul className='bulletins'>
                                <li>Custom software applications development</li>
                                <li>Ecommerce Web Site Implementation</li>
                                <li>Native and hybrid mobile app development</li>
                                <li>Responsive web site development</li>
                                <li>Software maintenance</li>
                            </ul>
                        </div>
                        <div class="col-xl-12 col-md-12 mb-2">
                            <h2 className="topic-head">OUR DOMAIN</h2>
                            <p className="para-text">We focus on different industries like</p>
                            <ul className='bulletins'>
                                <li>Automobile</li>
                                <li>Banking & Financial Services</li>
                                <li>Education</li>
                                <li>Food & Beverages</li>
                                <li>Healthcare</li>
                                <li>Information Technology</li>
                                <li>Retail</li>
                            </ul>
                        </div>
                        <div class="col-xl-12 col-md-12 mb-5">
                            <h2 className="topic-head">OUR TARGET</h2>
                            <p className="para-text m-0">We help individual businesses and company owners to develop, establish higher visibility of their specific applications.</p>
                            <p className="para-text m-0">We are committed in providing the apps and website development services they need to build, monitor and establish their business more efficiently.</p>
                            <p className="para-text m-0">We provide various services at cost effective prices with high quality, and this is being the main reason why most of the clients prefer us for developing their software or applications.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="abtdum p-5" ></div>

             <section className='free'>
                <div className='freeSub'>
                    <div className='freeSub1 '>
                        <h2 className='freefont'>Free consultation!</h2>
                    </div>
                    <div className='freeSub1 inocard'>
                        <h2 className='freefont1'>+91 6379444534</h2>
                        <p className='info'>info@keprevos.com</p>
                    </div>
                    <div className='freeSub1 mailcard'>

                        <div className='btnonline'>
                            <p>Online contact form</p>
                            <IoMdMail className='mailbrn2' />

                        </div>

                    </div>

                </div>
            </section>

            <section className='serFooter'>

                <div class='row footrow' style={{ margin: 0 }}>
                    <div class="col-12 col-md-12 col-lg-6 foot1">
                        <span className='footTxt1'>© 2020 KEPREVOS - All Rights Reserved</span>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 foot2">
                        <span className='footTxt2'>
                            <FaPhoneSquare className='footIcon' />
                            <p>+91 6379444534 </p>
                        </span>

                        <span className='footTxt2'>
                            <IoMdMail className='footIconmail' />
                            <p>management@keprevos.com </p>
                        </span>

                        <span className='footTxt2'>
                            <AiFillFacebook className='footIconmail' />
                        </span>

                        <span className='footTxt2'>
                            <BsWhatsapp className='footIconwatt' />
                        </span>

                        <span className='footTxt2'>
                            <AiFillLinkedin className='footIconwatt' />
                        </span>

                    </div>
                </div>

            </section>
        </>
    )
}

export default About