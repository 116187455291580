import React, { useState } from "react";
import './Service.css';
import './Technologies.css';
import './Home.css';
import { Routes, Route, Link, NavLink } from "react-router-dom";
import { AiOutlineEnvironment, } from "react-icons/ai";
import { FaPhoneSquare, } from "react-icons/fa";
import { IoMdMail, } from "react-icons/io";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsWhatsapp, } from "react-icons/bs";

const Technologies = () => {

    const [mobside, setMobside] = useState(0);

    const openNav = () => {
        setMobside(250);
    }

    const closeNav = () => {
        setMobside(0);
    }

    return (
        <>

            <section className='serHead techmainingbg'>

                <div className='subhead1'>
                    <div className='subhead11'>
                        <a href="../">
                            <img src={require('../../asset/img/keplogo.png')} alt="logo" style={{ width: 170 }} className="headimgserv"/>
                        </a>
                    </div>
                    <div className='subhead12'>
                        <nav className='navtab' >
                            <ul className="ulhead">
                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/home">Home</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/service">Services</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/technologies">Technologies</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/portfolio1">Portfolio</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/about">About</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/contact">Contact</NavLink></li>
                            </ul>
                        </nav>

                        <div className='mobsidemew'>
                            <span className='mobsidemew1' onClick={() => openNav()} >&#9776;</span>
                        </div>

                    </div>
                </div>
                <div className='subhead2'>

                    <span className='subhead_text1 tecq1'>TECHNOLOGIES</span>
                    <span className='subhead_text2'>Always ahead of the race.</span>
                    {/* <span className='subhead_text3'>Home / Services</span> */}

                </div>

            </section>


            <section id="mySidenav" style={{ width: mobside }} class="sidenav">
                <a class="closebtn" onClick={() => closeNav()}>&times;</a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/home">Home</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/service">Services</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/technologies">Technologies</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/portfolio1">Portfolio</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/about">About</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/contact">Contact</NavLink>
                </a>
            </section>

            <section className='maintech'>
                <div className='maintechsub'>
                    <div className='maintechsub1'>
                        <div className='maintechsub11'>
                            <div className='maintec12'>
                                <img src={require('../../asset/img/tecjs.png')} alt="logo" style={{ width: 80 }} />
                                <p className='parajs'>Javascript</p>

                            </div>
                            <div className='maintec13'>

                                <img src={require('../../asset/img/tecang.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/tecreact.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/tecrnewjs.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/tecrnew2.png')} alt="logo" style={{ width: 35, height: 35 }} />

                            </div>
                        </div>
                        <div className='maintechsub11'>
                            <div className='maintec12'>
                                <img src={require('../../asset/img/dbimg.png')} alt="logo" style={{ width: 80 }} />
                                <p className='parajs'>Database</p>

                            </div>
                            <div className='maintec13'>

                                <img src={require('../../asset/img/db1.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/db2.png')} alt="logo" style={{ width: 22, height: 45 }} />
                                <img src={require('../../asset/img/db3.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/db4.png')} alt="logo" style={{ width: 35, height: 35 }} />

                            </div>
                        </div>
                        <div className='maintechsub11'>
                            <div className='maintec12'>
                                <img src={require('../../asset/img/mdimg.png')} alt="logo" style={{ width: 80 }} />
                                <p className='parajs'>MobileApp Development</p>

                            </div>
                            <div className='maintec13'>

                                <img src={require('../../asset/img/mobile1.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/mobile2.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/mobile3.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/mobile4.png')} alt="logo" style={{ width: 35, height: 35 }} />

                            </div>
                        </div>
                        <div className='maintechsub11'>
                            <div className='maintec12'>
                                <img src={require('../../asset/img/arcimg.png')} alt="logo" style={{ width: 70 }} />
                                <p className='parajs'>Architecture</p>

                            </div>
                            <div className='maintec13'>

                                <img src={require('../../asset/img/arc1.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/arc2.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/arc3.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                {/* <img src={require('../../asset/img/arc3.png')} alt="logo" style={{ width: 35, height: 35}} /> */}

                            </div>
                        </div>
                    </div>
                    <div className='maintechsub2'>
                        <div className='maintechsub11'>
                            <div className='maintec12'>
                                <img src={require('../../asset/img/serverimg.png')} alt="logo" style={{ width: 138 }} />
                                <p className='parajs'>Server Side</p>

                            </div>
                            <div className='maintec13'>

                                <img src={require('../../asset/img/server1.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/server2.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/server3.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/server4.png')} alt="logo" style={{ width: 35, height: 35 }} />

                            </div>
                        </div>
                        <div className='maintechsub11'>
                            <div className='maintec12'>
                                <img src={require('../../asset/img/devopimg.png')} alt="logo" style={{ width: 100 }} />
                                <p className='parajs newdevops'>Devops</p>

                            </div>
                            <div className='maintec13'>

                                <img src={require('../../asset/img/devops1.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/devops2.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/devops3.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/devops4.png')} alt="logo" style={{ width: 35, height: 35 }} />

                            </div>
                        </div>
                        <div className='maintechsub11'>
                            <div className='maintec12'>
                                <img src={require('../../asset/img/cloudimg.png')} alt="logo" style={{ width: 75 }} />
                                <p className='parajs'>cloud services</p>

                            </div>
                            <div className='maintec13'>

                                <img src={require('../../asset/img/cloud1.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/cloud2.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/cloud3.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/cloud4.png')} alt="logo" style={{ width: 35, height: 35 }} />

                            </div>
                        </div>
                        <div className='maintechsub11'>
                            <div className='maintec12'>
                                <img src={require('../../asset/img/desktopimg.png')} alt="logo" style={{ width: 75 }} />
                                <p className='parajs'>Desktop Applications</p>

                            </div>
                            <div className='maintec13'>

                                <img src={require('../../asset/img/dapp1.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/dapp2.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                <img src={require('../../asset/img/dapp3.png')} alt="logo" style={{ width: 35, height: 35 }} />
                                {/* <img src={require('../../asset/img/tecrnew2.png')} alt="logo" style={{ width: 35, height: 35}} /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='serFooter'>

                <div class='row footrow' style={{ margin: 0 }}>
                    <div class="col-12 col-md-12 col-lg-6 foot1">
                        <span className='footTxt1'>© 2020 KEPREVOS - All Rights Reserved</span>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 foot2">
                        <span className='footTxt2'>
                            <FaPhoneSquare className='footIcon' />
                            <p>+91 6379444534 </p>
                        </span>

                        <span className='footTxt2'>
                            <IoMdMail className='footIconmail' />
                            <p>management@keprevos.com </p>
                        </span>

                        <span className='footTxt2'>
                            <AiFillFacebook className='footIconmail' />
                        </span>

                        <span className='footTxt2'>
                            <BsWhatsapp className='footIconwatt' />
                        </span>

                        <span className='footTxt2'>
                            <AiFillLinkedin className='footIconwatt' />
                        </span>

                    </div>
                </div>

            </section>
        </>
    )
}

export default Technologies