import React, { useState } from "react";
import './Contact.css';
import './Service.css';
import './Home.css';
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { AiOutlineEnvironment, } from "react-icons/ai";
import { FaPhoneSquare, } from "react-icons/fa";
import { IoMdMail, } from "react-icons/io";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsWhatsapp, } from "react-icons/bs";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const Contact = () => {

    const [mobside, setMobside] = useState(0);

    const openNav = () => {
        setMobside(250);
    }

    const closeNav = () => {
        setMobside(0);
    }

    return (
        <>
            <section className='serHead conmainingbg'>

                <div className='subhead1'>
                    <div className='subhead11'>
                        <a href="../">
                            <img src={require('../../asset/img/keplogo.png')} alt="logo" style={{ width: 170 }} className="headimgserv" />
                        </a>
                    </div>
                    <div className='subhead12'>
                        <nav className='navtab' >
                            <ul className="ulhead">
                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/home">Home</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/service">Services</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/technologies">Technologies</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/portfolio1">Portfolio</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/about">About</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/contact">Contact</NavLink></li>
                            </ul>
                        </nav>
                        <div className='mobsidemew'>
                            <span className='mobsidemew1' onClick={() => openNav()} >&#9776;</span>
                        </div>

                    </div>
                </div>
                <div className='subhead2'>

                    <span className='subhead_text1 contheat'>CONTACT US</span>
                    <span className='subhead_text2'>We are open for new projects!</span>
                    {/* <span className='subhead_text3'>Home / Services</span> */}

                </div>

            </section>

            <section id="mySidenav" style={{ width: mobside }} class="sidenav">
                <a class="closebtn" onClick={() => closeNav()}>&times;</a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/home">Home</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/service">Services</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/technologies">Technologies</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/portfolio1">Portfolio</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/about">About</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/contact">Contact</NavLink>
                </a>
            </section>

            <div className="condum" ></div>

            <section className='sec2-bg'>
                <div className="container-fluid pt-5 pb-5">
                    <div className="row mx-auto">
                        <div className="col-xl-4 col-md-4 mb-4 mt-2">
                            <div className="m-2 d-flex sec2-col"><img src={require('../../asset/img/phone-icon.png')} alt="phone" style={{ width: 48, marginBottom: 10 }} /><p className='sec2-text1'>Call us</p><p className='sec2-text contact-phno'>+91 6379444534</p></div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-4 mt-2">
                            <div className="m-2 d-flex sec2-col"> <img src={require('../../asset/img/mail-icon.png')} alt="mail" style={{ width: 68, marginBottom: 10 }} /><p className='sec2-text1'>Mail</p> <p className='sec2-text'>info@keprevos.com</p></div>
                        </div>
                        <div className="col-xl-4 col-md-4 mb-4 mt-2">
                            <div className="m-2 d-flex sec2-col"><img src={require('../../asset/img/location-icon.png')} alt="location" style={{ width: 48, marginBottom: 10 }} /><p className='sec2-text1'>Location</p><p className='sec2-text p-2'>Plot No: 2 & 3, 2nd Floor,<br/>1st Cross Street, Anna Nagar,<br/> Nellithope, Puducherry - 605 005.</p></div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="abtdum p-4" ></div>

            <div className=" p-4" ></div>

            <section className='sec3-bg'>
                <div className="container pb-5">
                    <div className="row mx-auto">
                        <div className="col-xl-12 col-md-12 mx-auto text-center p-3 d-flex justify-content-center align-items-center">
                            <div style={{ width: 3, height: 55, backgroundColor: '#148ba9', borderRadius: 10 }}></div>
                        </div>
                        <div className="newgetin">
                            <h2 className='pb-3 headtxt'>Get In Touch!</h2>
                            <h5 className='pb-3 headsubtxt'>And we will get back to you ASAP.</h5>
                            <Form method="post" className='cobtactform' >
                                <div className="row formrow1">
                                    <div className="col-md-9">
                                        <div className="single-contact-field">

                                            <Form.Group className="mb-4" controlId="formname">
                                                <Form.Control type="text" className="inpform1" placeholder="Name *" />
                                            </Form.Group>

                                        </div>
                                    </div>

                                    <div className="col-md-9">
                                        <div className="single-contact-field">

                                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                                <Form.Control type="email" className="inpform1" placeholder="E-mail *" />
                                            </Form.Group>

                                        </div>
                                    </div>
                                </div>
                                <div className="row formrow1">
                                    <div className="col-md-9">
                                        <div className="single-contact-field">

                                            <Form.Group className="mb-4" controlId="formcompany">
                                                <Form.Control type="text"className="inpform1" placeholder="Company *" />
                                            </Form.Group>

                                        </div>
                                    </div>

                                </div>
                                <div className="row formrow1">
                                    <div className="col-md-9">
                                        <div className="single-contact-field">

                                            <FloatingLabel controlId="floatingTextarea2" >
                                                <Form.Control
                                                    as="textarea"
                                                   className="inpform1" placeholder="Enter Your Message *"
                                                    style={{ height: '100px' }}
                                                />
                                            </FloatingLabel>

                                        </div>
                                    </div>
                                </div>
                                <div className="row formrow1">
                                    <div className="col-md-9">
                                        <div className="single-contact-field" style={{ float: 'right' }}>

                                            {/* <Button style={{ marginTop: 5, width: '10em', height: '3em', backgroundColor: '#148ba9', borderRadius: '30px' }} variant="success">SUBMIT NOW</Button>{' '} */}

                                            <div className='cardbtncont'>
                                                <p className='readText'>SUBMIT NOW</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>

            <div className=" p-5" ></div>

            <section className='serFooter'>

                <div class='row footrow' style={{ margin: 0 }}>
                    <div class="col-12 col-md-12 col-lg-6 foot1">
                        <span className='footTxt1'>© 2020 KEPREVOS - All Rights Reserved</span>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 foot2">
                        <span className='footTxt2'>
                            <FaPhoneSquare className='footIcon' />
                            <p>+91 6379444534 </p>
                        </span>

                        <span className='footTxt2'>
                            <IoMdMail className='footIconmail' />
                            <p>management@keprevos.com </p>
                        </span>

                        <span className='footTxt2'>
                            <AiFillFacebook className='footIconmail' />
                        </span>

                        <span className='footTxt2'>
                            <BsWhatsapp className='footIconwatt' />
                        </span>

                        <span className='footTxt2'>
                            <AiFillLinkedin className='footIconwatt' />
                        </span>

                    </div>
                </div>

            </section>
        </>
    )
}

export default Contact