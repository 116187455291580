import React, { useState } from "react";
import './Service.css';
import './Home.css';
import { Routes, Route, Link, NavLink } from "react-router-dom";
import { AiOutlineEnvironment, } from "react-icons/ai";
import { FaPhoneSquare, } from "react-icons/fa";
import { IoMdMail, } from "react-icons/io";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsWhatsapp, } from "react-icons/bs";

const Service = () => {

    const [mobside, setMobside] = useState(0);

    const openNav = () => {
        setMobside(250);
    }

    const closeNav = () => {
        setMobside(0);
    }


    return (
        <>

            <section className='serHead'>

                <div className='subhead1'>
                    <div className='subhead11'>
                        <a href="../">
                            <img src={require('../../asset/img/keplogo.png')} alt="logo" style={{ width: 170 }} className="headimgserv" />
                        </a>
                    </div>
                    <div className='subhead12'>
                        <nav className='navtab' >
                            <ul className="ulhead">
                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/home">Home</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/service">Services</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/technologies">Technologies</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/portfolio1">Portfolio</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/about">About</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/contact">Contact</NavLink></li>
                            </ul>
                        </nav>
                        <div className='mobsidemew'>
                            <span className='mobsidemew1' onClick={() => openNav()} >&#9776;</span>
                        </div>

                    </div>
                </div>
                <div className='subhead2'>

                    <span className='subhead_text1'>OUR SERVICES</span>
                    <span className='subhead_text2'>What we are good at</span>
                    <span className='subhead_text3'>Home / Services</span>

                </div>

            </section>

            <section id="mySidenav" style={{ width: mobside }} class="sidenav">
                <a class="closebtn" onClick={() => closeNav()}>&times;</a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/home">Home</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/service">Services</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/technologies">Technologies</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/portfolio1">Portfolio</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/about">About</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/contact">Contact</NavLink>
                </a>
            </section>

            <section className='main'>

                <div className='maincard'>

                    <div className='maincard1'>

                        <div className='subcard1 scard'>

                            <div className='scard1'>
                                <img src={require('../../asset/img/cardimg1.png')} alt="logo" style={{ width: 50 }} />
                            </div>
                            <div className='scard1 webpara'>
                                <p className='para1scard'>Website - Responsive, PWA</p>
                                <p className='para2scard'>KEPREVOS is the superior and trusted name for the best progressive web…</p>
                            </div>
                            <div className='scard1'>

                                <div className='cardbtn'>
                                    <p className='readText'>Read More</p>
                                </div>

                            </div>

                        </div>
                        <div className='subcard1 scard'>

                            <div className='scard1'>
                                <img src={require('../../asset/img/cardimg2.png')} alt="logo" style={{ width: 50 }} />
                            </div>
                            <div className='scard1 webpara'>
                                <p className='para1scard'>Web Applications</p>
                                <p className='para2scard'>If you are looking for the best and high-quality web application…</p>
                            </div>
                            <div className='scard1'>

                                <div className='cardbtn'>
                                    <p className='readText'>Read More</p>
                                </div>

                            </div>

                        </div>
                        <div className='subcard1 scard'>

                            <div className='scard1'>
                                <img src={require('../../asset/img/cardimg3.png')} alt="logo" style={{ width: 50 }} />
                            </div>
                            <div className='scard1 webpara'>
                                <p className='para1scard'>Mobile App Development</p>
                                <p className='para2scard'>KEPREVOS is a professional Mobile app development service company… </p>
                            </div>
                            <div className='scard1'>

                                <div className='cardbtn'>
                                    <p className='readText'>Read More</p>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className='maincard2'>

                        <div className='subcard1 scard'>

                            <div className='scard1'>
                                <img src={require('../../asset/img/cardimg4.png')} alt="logo" style={{ width: 50 }} />
                            </div>
                            <div className='scard1 webpara'>
                                <p className='para1scard'>eCommerce</p>
                                <p className='para2scard'>KEPREVOS is the best eCommerce development company in Pondy… </p>
                            </div>
                            <div className='scard1'>

                                <div className='cardbtn'>
                                    <p className='readText'>Read More</p>
                                </div>

                            </div>

                        </div>
                        <div className='subcard1 scard'>

                            <div className='scard1'>
                                <img src={require('../../asset/img/cardimg5.png')} alt="logo" style={{ width: 50 }} />
                            </div>
                            <div className='scard1 webpara'>
                                <p className='para1scard'>Analytics</p>
                                <p className='para2scard'>Visualize your Business Insights through our dashboards at a glance…</p>
                            </div>
                            <div className='scard1'>

                                <div className='cardbtn'>
                                    <p className='readText'>Read More</p>
                                </div>

                            </div>

                        </div>
                        <div className='subcard1 scard'>

                            <div className='scard1'>
                                <img src={require('../../asset/img/cardimg3.png')} alt="logo" style={{ width: 50 }} />
                            </div>
                            <div className='scard1 webpara'>
                                <p className='para1scard'>Customer Support Services</p>
                                <p className='para2scard'>Customer service is the key to customer retention. Customer care… </p>
                            </div>
                            <div className='scard1'>

                                <div className='cardbtn'>
                                    <p className='readText'>Read More</p>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <section className='free'>

                <div className='freeSub'>

                    <div className='freeSub1 '>
                        <h2 className='freefont'>Free consultation!</h2>
                    </div>
                    <div className='freeSub1 inocard'>
                        <h2 className='freefont1'>+91 6379444534</h2>
                        <p className='info'>info@keprevos.com</p>
                    </div>
                    <div className='freeSub1 mailcard'>

                        <div className='btnonline'>
                            <p>Online contact form</p>
                            <IoMdMail className='mailbrn2' />

                        </div>

                    </div>

                </div>

            </section>

            <section className='serFooter'>

                <div class='row footrow' style={{ margin: 0 }}>
                    <div class="col-12 col-md-12 col-lg-6 foot1">
                        <span className='footTxt1'>© 2020 KEPREVOS - All Rights Reserved</span>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 foot2">
                        <span className='footTxt2'>
                            <FaPhoneSquare className='footIcon' />
                            <p>+91 6379444534 </p>
                        </span>

                        <span className='footTxt2'>
                            <IoMdMail className='footIconmail' />
                            <p>management@keprevos.com </p>
                        </span>

                        <span className='footTxt2'>
                            <AiFillFacebook className='footIconmail' />
                        </span>

                        <span className='footTxt2'>
                            <BsWhatsapp className='footIconwatt' />
                        </span>

                        <span className='footTxt2'>
                            <AiFillLinkedin className='footIconwatt' />
                        </span>

                    </div>
                </div>

            </section>

        </>
    )
}

export default Service