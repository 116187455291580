import React, { useState } from "react";
import './Service.css';
import './Technologies.css';
import './Portfolio.css';
import { Routes, Route, Link, NavLink } from "react-router-dom";
import { AiOutlineEnvironment, } from "react-icons/ai";
import { FaPhoneSquare, } from "react-icons/fa";
import { IoMdMail, } from "react-icons/io";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsWhatsapp, } from "react-icons/bs";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import FsLightbox from "fslightbox-react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Portmode from "./Portmode";

// function MyVerticallyCenteredModal(props) {
//     return (
//       <Modal
//         {...props}
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter">
//             Modal heading
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <h4>Centered Modal</h4>
//           <p>
//             Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
//             dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
//             consectetur ac, vestibulum at eros.
//           </p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button onClick={props.onHide}>Close</Button>
//         </Modal.Footer>
//       </Modal>
//     );
//   }

const Portfolio = () => {
    const imgdata = [{
        "text": "Xpert Mobile App",
        "link": require("../../asset/img/portfolio/Xpert-mob.jpg")
    },
    {
        "text": "Harit",
        "link": require("../../asset/img/portfolio/harit.jpg")
    },
    {
        "text": "XPB Website",
        "link": require("../../asset/img/portfolio/xpb-web.jpg")
    },
    {
        "text": "Your Ideas Mobile App",
        "link": require("../../asset/img/portfolio/your-ideas-mob.jpg")
    },
    {
        "text": "Your Ideas",
        "link": require("../../asset/img/portfolio/your-ideas.jpg")
    },
    {
        "text": "Xpert Mobile App",
        "link": require("../../asset/img/portfolio/Xpert-mobile.jpg")
    },
    {
        "text": "XPB Mobile",
        "link": require("../../asset/img/portfolio/xpb-mobile.jpg")
    }];
    const indiadata = [
    {
        "text": "Harit",
        "link": require("../../asset/img/portfolio/harit.jpg")
    },
    {
        "text": "Your Ideas Mobile App",
        "link": require("../../asset/img/portfolio/your-ideas-mob.jpg")
    },
    {
        "text": "Your Ideas",
        "link": require("../../asset/img/portfolio/your-ideas.jpg")
    }];
    const overseasdata = [{
        "text": "Xpert",
        "link": require("../../asset/img/portfolio/Xpert-mob.jpg")
    },
    {
        "text": "XPB Website",
        "link": require("../../asset/img/portfolio/xpb-web.jpg")
    },
    {
        "text": "Xpert Mobile App",
        "link": require("../../asset/img/portfolio/Xpert-mobile.jpg")
    },
    {
        "text": "XPB Mobile",
        "link": require("../../asset/img/portfolio/xpb-mobile.jpg")
    }];
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [dynamicdata, setDynamicdata] = useState(null);

    const handleClick = (item, index, dynamicimage) => {
      setCurrentIndex(index);
      setClickedImg(item.link);
      setDynamicdata(dynamicimage);
      console.log("Index: "+index);
      console.log("Link: "+item.link);
      console.log("Clicked:"+dynamicimage);
    };
  
    const handelRotationRight = () => {
      console.log("Which data: "+dynamicdata);
      if(dynamicdata === 'indiadata')
      {
                    const totalLength = indiadata.length;
                if (currentIndex + 1 >= totalLength) {
                    setCurrentIndex(0);
                    const newUrl = indiadata[0].link;
                    setClickedImg(newUrl);
                    return;
                }
                const newIndex = currentIndex + 1;
                const newUrl = indiadata.filter((item) => {
                    return indiadata.indexOf(item) === newIndex;
                });
                const newItem = newUrl[0].link;
                setClickedImg(newItem);
                setCurrentIndex(newIndex);
      }
      else if(dynamicdata === 'overseasdata')
      {
                const totalLength = overseasdata.length;
                if (currentIndex + 1 >= totalLength) {
                    setCurrentIndex(0);
                    const newUrl = overseasdata[0].link;
                    setClickedImg(newUrl);
                    return;
                }
                const newIndex = currentIndex + 1;
                const newUrl = overseasdata.filter((item) => {
                    return overseasdata.indexOf(item) === newIndex;
                });
                const newItem = newUrl[0].link;
                setClickedImg(newItem);
                setCurrentIndex(newIndex);
      }
      else
      {
            const totalLength = imgdata.length;
            if (currentIndex + 1 >= totalLength) {
                setCurrentIndex(0);
                const newUrl = imgdata[0].link;
                setClickedImg(newUrl);
                return;
            }
            const newIndex = currentIndex + 1;
            const newUrl = imgdata.filter((item) => {
                return imgdata.indexOf(item) === newIndex;
            });
            const newItem = newUrl[0].link;
            setClickedImg(newItem);
            setCurrentIndex(newIndex);
      }
    };
  
    const handelRotationLeft = () => {
        if(dynamicdata === 'indiadata')
        {
            const totalLength = indiadata.length;
            if (currentIndex === 0) {
                setCurrentIndex(totalLength - 1);
                const newUrl = indiadata[totalLength - 1].link;
                setClickedImg(newUrl);
                return;
            }
            const newIndex = currentIndex - 1;
            const newUrl = indiadata.filter((item) => {
                return indiadata.indexOf(item) === newIndex;
            });
            const newItem = newUrl[0].link;
            setClickedImg(newItem);
            setCurrentIndex(newIndex);
        }
        else if(dynamicdata === 'overseasdata')
        {
            const totalLength = overseasdata.length;
            if (currentIndex === 0) {
                setCurrentIndex(totalLength - 1);
                const newUrl = overseasdata[totalLength - 1].link;
                setClickedImg(newUrl);
                return;
            }
            const newIndex = currentIndex - 1;
            const newUrl = overseasdata.filter((item) => {
                return overseasdata.indexOf(item) === newIndex;
            });
            const newItem = newUrl[0].link;
            setClickedImg(newItem);
            setCurrentIndex(newIndex);
        }

        else
        {
            const totalLength = imgdata.length;
            if (currentIndex === 0) {
                setCurrentIndex(totalLength - 1);
                const newUrl = imgdata[totalLength - 1].link;
                setClickedImg(newUrl);
                return;
            }
            const newIndex = currentIndex - 1;
            const newUrl = imgdata.filter((item) => {
                return imgdata.indexOf(item) === newIndex;
            });
            const newItem = newUrl[0].link;
            setClickedImg(newItem);
            setCurrentIndex(newIndex);
        }
    };
    
    const [modalShow, setModalShow] = React.useState(false);


    return (
        <>

            <section className='serHead'>

                <div className='subhead1'>
                    <div className='subhead11'>
                        <a href="../">
                            <img src={require('../../asset/img/keplogo.png')} alt="logo" style={{ width: 170 }} />
                        </a>
                    </div>
                    <div className='subhead12'>
                        <nav className='navtab' >
                            <ul className="ulhead">
                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/home">Home</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/service">Services</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/technologies">Technologies</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/portfolio">Portfolio</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/about">About</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/contact">Contact</NavLink></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className='subhead2'>

                    <span className='subhead_text1'>Portfolio</span>
                    <span className='subhead_text2'>Check out our projects!</span>
                    {/* <span className='subhead_text3'>Home / Services</span> */}

                </div>

            </section>

            <section className='mainport'>
                <div className='container'>
                    <div className='row pt-5 vh-100 mb-5'>
                        <div className='col-xl-12 col-md-12'>
                        <Tabs defaultActiveKey="first">
                            <Tab eventKey="first" title="VIEW ALL" tabClassName="">
                                <div className="wrapper">
                                    {imgdata.map((item, index) => (
                                        <div key={index} className="wrapper-images">
                                            <img src={item.link} alt={item.text} onClick={() => handleClick(item, index, 'viewall')} style={{ width: 220, height: 'auto' }}/>
                                            {/* <h2>{item.text}</h2> */}
                                        </div>
                                    ))}
                                    {clickedImg &&
                                     <Portmode 
                                     clickedImg={clickedImg}
                                     handelRotationRight={handelRotationRight}
                                     setClickedImg={setClickedImg}
                                     handelRotationLeft={handelRotationLeft}
                                     />}
                                </div>
                            </Tab>
                            <Tab eventKey="second" title="INDIA" >
                            <div className="wrapper">
                                    {indiadata.map((item, index) => (
                                        <div key={index} className="wrapper-images">
                                            <img src={item.link} alt={item.text} onClick={() => handleClick(item, index, 'indiadata')} style={{ width: 220, height: 'auto' }}/>
                                            {/* <h2>{item.text}</h2> */}
                                        </div>
                                    ))}
                                    {clickedImg &&
                                     <Portmode 
                                     clickedImg={clickedImg}
                                     handelRotationRight={handelRotationRight}
                                     setClickedImg={setClickedImg}
                                     handelRotationLeft={handelRotationLeft}
                                     />}
                                </div>
                            </Tab>
                            <Tab eventKey="third" title="OVERSEAS" tabClassName="testclass">
                            <div className="wrapper">
                                    {overseasdata.map((item, index) => (
                                        <div key={index} className="wrapper-images">
                                            <img src={item.link} alt={item.text} onClick={() => handleClick(item, index, 'overseasdata')} style={{ width: 220, height: 'auto' }}/>
                                            {/* <h2>{item.text}</h2> */}
                                        </div>
                                    ))}
                                    {clickedImg &&
                                     <Portmode 
                                     clickedImg={clickedImg}
                                     handelRotationRight={handelRotationRight}
                                     setClickedImg={setClickedImg}
                                     handelRotationLeft={handelRotationLeft}
                                     />}
                                </div>
                            </Tab>
                        </Tabs> 
                        </div>
                    </div>
                </div>
            </section>


            <section className='serFooter'>

                <div class='row' style={{ margin: 0 }}>
                    <div class="col-6 foot1">
                        <span className='footTxt1'>© 2020 KEPREVOS - All Rights Reserved</span>
                    </div>
                    <div class="col-6 foot2">
                        <span className='footTxt2'>
                            <FaPhoneSquare className='footIcon' />
                            <p>+91 6379444534 </p>
                        </span>

                        <span className='footTxt2'>
                            <IoMdMail className='footIconmail' />
                            <p>management@keprevos.com </p>
                        </span>

                        <span className='footTxt2'>
                            <AiFillFacebook className='footIconmail' />
                        </span>

                        <span className='footTxt2'>
                            <BsWhatsapp className='footIconwatt' />
                        </span>

                        <span className='footTxt2'>
                            <AiFillLinkedin className='footIconwatt' />
                        </span>

                    </div>
                </div>

            </section>

        </>
    )
}

export default Portfolio