import React, { useState } from "react";
import './Service.css';
import './Technologies.css';
import './Home.css';
import './Portfolio1.css';
import { Routes, Route, Link, NavLink } from "react-router-dom";
import { AiOutlineEnvironment, } from "react-icons/ai";
import { FaPhoneSquare, } from "react-icons/fa";
import { IoMdMail, } from "react-icons/io";
import { BiExpandAlt, } from "react-icons/bi";
import { AiFillFacebook, AiFillLinkedin } from "react-icons/ai";
import { BsWhatsapp, } from "react-icons/bs";
import carouselimg6 from '../../asset/img/carousel/carouselimg6.jpg';

import Xpert from '../../asset/img/portfolio/Xpert-mob.jpg';


const Portfolio = () => {

    const [mobside, setMobside] = useState(0);

    const openNav = () => {
        setMobside(250);
    }

    const closeNav = () => {
        setMobside(0);
    }

    const imgdataover = [{
        "id": 1,
        "text": "Xpert",
        "link": require("../../asset/img/portfolio/Xpert-mob.jpg")
    },
    {
        "id": 2,
        "text": "Harit",
        "link": require("../../asset/img/portfolio/harit.jpg")
    },
    {
        "id": 3,
        "text": "XPB",
        "link": require("../../asset/img/portfolio/xpb-web.jpg")
    },
    {
        "id": 4,
        "text": "Your Ideas",
        "link": require("../../asset/img/portfolio/your-ideas-mob.jpg")
    },
    {
        "id": 5,
        "text": "Your Ideas Web",
        "link": require("../../asset/img/portfolio/your-ideas.jpg")
    },
    {
        "id": 6,
        "text": "Xpert Mobile",
        "link": require("../../asset/img/portfolio/Xpert-mobile.jpg")
    },
    {
        "id": 7,
        "text": "XPB Mobile",
        "link": require("../../asset/img/portfolio/xpb-mobile.jpg")
    }
    ];

    const imgdataover2 = [
        {
            "id": 8,
            "text": "Harit",
            "link": require("../../asset/img/portfolio/harit.jpg")
        },
        {
            "id": 9,
            "text": "Your Ideas",
            "link": require("../../asset/img/portfolio/your-ideas-mob.jpg")
        },
        {
            "id": 10,
            "text": "Your Ideas Web",
            "link": require("../../asset/img/portfolio/your-ideas.jpg")
        },
    ];

    const imgdataover3 = [{
        "id": 11,
        "text": "Xpert",
        "link": require("../../asset/img/portfolio/Xpert-mob.jpg")
    },
    {
        "id": 12,
        "text": "XPB",
        "link": require("../../asset/img/portfolio/xpb-web.jpg")
    },
    {
        "id": 13,
        "text": "Xpert Mobile",
        "link": require("../../asset/img/portfolio/Xpert-mobile.jpg")
    },
    {
        "id": 14,
        "text": "XPB Mobile",
        "link": require("../../asset/img/portfolio/xpb-mobile.jpg")
    }

    ];

    const [tab1, setTab1] = useState(true);
    const [tab2, setTab2] = useState(false);
    const [tab3, setTab3] = useState(false);

    const Tab1 = () => {
        setTab1(true);
        setTab2(false);
        setTab3(false);
    }
    const Tab2 = () => {
        setTab1(false);
        setTab2(true);
        setTab3(false);
    }
    const Tab3 = () => {
        setTab1(false);
        setTab2(false);
        setTab3(true);
    }

    return (
        <>

            <section className='serHead portfomainingbg'>

                <div className='subhead1'>
                    <div className='subhead11'>
                        <a href="../">
                            <img src={require('../../asset/img/keplogo.png')} alt="logo" style={{ width: 170 }} className="headimgserv"/>
                        </a>
                    </div>
                    <div className='subhead12'>
                        <nav className='navtab' >
                            <ul className="ulhead">
                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/home">Home</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/service">Services</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/technologies">Technologies</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/portfolio1">Portfolio</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/about">About</NavLink></li>

                                <li class="active"><NavLink style={({ isActive }) => ({
                                    color: isActive ? "white" : "white ",
                                })} to="/contact">Contact</NavLink></li>
                            </ul>
                        </nav>

                        <div className='mobsidemew'>
                            <span className='mobsidemew1' onClick={() => openNav()} >&#9776;</span>
                        </div>

                    </div>
                </div>
                <div className='subhead2'>

                    <span className='subhead_text1'>Portfolio</span>
                    <span className='subhead_text2'>Check out our projects!</span>
                    {/* <span className='subhead_text3'>Home / Services</span> */}

                </div>

            </section>

            <section id="mySidenav" style={{ width: mobside }} class="sidenav">
                <a class="closebtn" onClick={() => closeNav()}>&times;</a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/home">Home</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/service">Services</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/technologies">Technologies</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/portfolio1">Portfolio</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/about">About</NavLink>
                </a>
                <a href="#">
                    <NavLink style={({ isActive }) => ({
                        color: isActive ? "#007bff" : "black ",
                    })} to="/contact">Contact</NavLink>
                </a>
            </section>

            <section className='tabport'>

                <div className="row tabsub">

                    <div className="col-4">
                        <div className='cardbtnport1' onClick={() => Tab1()} style={{ backgroundColor: tab1 ? '#118ba9' : 'transparent', color: tab1 ? 'white' : 'black', }}>
                            <p className='readTextport1'>VIEW ALL </p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='cardbtnport1' onClick={() => Tab2()} style={{ backgroundColor: tab2 ? '#118ba9' : 'transparent', color: tab2 ? 'white' : 'black', }}>
                            <p className='readTextport1'>INDIA</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='cardbtnport1' onClick={() => Tab3()} style={{ backgroundColor: tab3 ? '#118ba9' : 'transparent', color: tab3 ? 'white' : 'black', }}>
                            <p className='readTextport1'>OVERSEAS</p>
                        </div>
                    </div>

                </div>

            </section>

            <section className="photosec">

                <div className='row portmainvew1' >

                    {
                        tab1 ?
                            <>
                                {
                                    imgdataover.map((data) => {

                                        return (
                                            <div key={data.id} data-toggle="modal" data-target={'#myModal' + data.id} className='col-md-6 col-lg-3 col-sm-12 poert1imgc' style={{ backgroundImage: `url(${data.link})` }} >
                                                <div className="portshow">
                                                    <div className="expincon">
                                                        <BiExpandAlt className="expin1" />
                                                    </div>
                                                    <p className="exptes">{data.text}</p>
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                            </>
                            :
                            <>
                                {
                                    tab2 ?
                                        <>
                                            {
                                                imgdataover2.map((data) => {

                                                    return (
                                                        <div key={data.id} data-toggle="modal" data-target={'#myModal' + data.id} className='col-md-6 col-lg-3 col-sm-12 poert1imgc' style={{ backgroundImage: `url(${data.link})` }} >
                                                            <div className="portshow">
                                                                <div className="expincon">
                                                                    <BiExpandAlt className="expin1" />
                                                                </div>
                                                                <p className="exptes">{data.text}</p>
                                                            </div>
                                                        </div>
                                                    )

                                                })
                                            }
                                            <div className='col-3 poert1imgc indeasec'>

                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                imgdataover3.map((data) => {

                                                    return (
                                                        <div key={data.id} data-toggle="modal" data-target={'#myModal' + data.id} className='col-md-6 col-lg-3 col-sm-12 poert1imgc' style={{ backgroundImage: `url(${data.link})` }} >
                                                            <div className="portshow">
                                                                <div className="expincon">
                                                                    <BiExpandAlt className="expin1" />
                                                                </div>
                                                                <p className="exptes">{data.text}</p>
                                                            </div>
                                                        </div>
                                                    )

                                                })
                                            }
                                        </>
                                }
                            </>
                    }




                    {/* {
                        imgdataover.map((data) => {

                            return (
                                <div key={data.id} data-toggle="modal" data-target={'#myModal' + data.id} className='col-3 poert1imgc' style={{ backgroundImage: `url(${data.link})` }} >
                                    <div className="portshow">
                                        <div className="expincon">
                                            <BiExpandAlt className="expin1" />
                                        </div>
                                        <p className="exptes">{data.text}</p>
                                    </div>
                                </div>
                            )

                        })
                    } */}

                </div>

            </section>

            <section className="modelshowsec">

                <>
                    {
                        imgdataover.map((data) => {

                            return (
                                <div key={data.id} class="modal fade" id={'myModal' + data.id} role="dialog">
                                    <div class="cusmodel1">

                                        <div class="cusmodel2" style={{ backgroundImage: `url(${data.link})` }}>
                                            <button type="button" class="close closemodel" data-dismiss="modal">&times;</button>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </>

                <>
                    {
                        imgdataover2.map((data) => {

                            return (
                                <div key={data.id} class="modal fade" id={'myModal' + data.id} role="dialog">
                                    <div class="cusmodel1">

                                        <div class="cusmodel2" style={{ backgroundImage: `url(${data.link})` }}>
                                            <button type="button" class="close closemodel" data-dismiss="modal">&times;</button>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </>

                <>
                    {
                        imgdataover3.map((data) => {

                            return (
                                <div key={data.id} class="modal fade" id={'myModal' + data.id} role="dialog">
                                    <div class="cusmodel1">

                                        <div class="cusmodel2" style={{ backgroundImage: `url(${data.link})` }}>
                                            <button type="button" class="close closemodel" data-dismiss="modal">&times;</button>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </>


            </section>

            <section className='serFooter'>

                <div class='row footrow' style={{ margin: 0 }}>
                    <div class="col-12 col-md-12 col-lg-6 foot1">
                        <span className='footTxt1'>© 2020 KEPREVOS - All Rights Reserved</span>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 foot2">
                        <span className='footTxt2'>
                            <FaPhoneSquare className='footIcon' />
                            <p>+91 6379444534 </p>
                        </span>

                        <span className='footTxt2'>
                            <IoMdMail className='footIconmail' />
                            <p>management@keprevos.com </p>
                        </span>

                        <span className='footTxt2'>
                            <AiFillFacebook className='footIconmail' />
                        </span>

                        <span className='footTxt2'>
                            <BsWhatsapp className='footIconwatt' />
                        </span>

                        <span className='footTxt2'>
                            <AiFillLinkedin className='footIconwatt' />
                        </span>

                    </div>
                </div>

            </section>

        </>
    )
}

export default Portfolio